<template>
  <CRow>
    <CCol>
      <div v-if="isSelectingDate">
        <CCard style="width:50%;margin:0 auto;box-shadow: 0 0 3px #d7d7d7;">
          <CCardHeader>
            <strong>新規棚卸し</strong>
          </CCardHeader>
          <CCardBody>
            <vue-confirm-dialog></vue-confirm-dialog>
            <CForm>
              <CRow>
                <CCol sm="9" style="margin:0 auto;">
                  <CInput
                    id="date"
                    :label="'棚卸し日'"
                    horizontal
                    v-model="inventory_worked_at"
                    type="date"
                  />
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter class="text-sm-center">
            <CButton @click="confirmGoToItemScan" color="primary">
              次へ
            </CButton>
          </CCardFooter>
        </CCard>
      </div>
      <div v-else>
        <SelectItemQR @submitCreateForm="submitForm" @goBack="goBackToSelectDate"></SelectItemQR>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from "@/api/resourceApi";
import SelectItemQR from "@/views/inventory-works/SelectItemQR";
import Vue from "vue";

const apiInventoryWork = new ResourceApi('inventory-works');

export default {
  name: 'InventoryWorkForm',
  components: {SelectItemQR},
  data () {
    return {
      inventory_worked_at: new Date().toISOString().slice(0, 10),
      isSelectingDate: true
    }
  },

  created() {},

  methods: {
    submitForm(val) {
      const params = {
        inventory_worked_at: this.inventory_worked_at,
        items: val.itemIds
      }

      apiInventoryWork.create(params).then(() => {
        Vue.$toast.success('登録成功しました。')
        setTimeout(this.$router.back(), 2000);
      }).catch(() => {
        Vue.$toast.error('少なくとも1つの製品が必要です')
      });
    },

    confirmGoToItemScan() {
      this.$confirm({
        title: '確認',
        message: '新規棚卸しの登録を行いますか？',
        button: {
          no: 'いいえ',
          yes: 'はい'
        },
        callback: confirm => {
          if (confirm) {
            this.goToItemQRScan()
          }
        }
      });
    },

    goToItemQRScan() {
      this.isSelectingDate = false;
    },

    goBackToSelectDate() {
      this.isSelectingDate = true;
    }
  }
}
</script>